.tag {
	@extend %tag;
}

.tag-black {
	@extend %tag;
	color: $black;
}

.tag-white {
	@extend %tag; 
	color: $white;
}