.footer {
	color: $white;
	margin-top: $gap-l;
	padding-block: $gap-l;
	background-color: $black;

	&-container {
		@extend %container;
	}

	&-nav {

		ul {
			@include flex(center, center, $gap-s);
			list-style: none;
			padding: 0;
		}
	}

	&-copyright {
		text-align: center;

		a {
			color: $white;
		}
	}
}