// spacing
$gap-xxxl: $spacing-xxxl;
$gap-xxl: $spacing-xxl;
$gap-xl: $spacing-xl;
$gap-l: $spacing-l;
$gap-m: $spacing-m;
$gap-s: $spacing-s;
$gap-xs: $spacing-xs;
$gap-xxs: $spacing-xxs;
$sliver: toRem(4);

// header (useful when using a fixed header to set top padding for main content)
$header-height: toRem(92);
$header-height-laptop: toRem(80);
$header-height-mobile: toRem(64);

// misc
$max-width: toRem(650);
$max-width-narrow: toRem(550);
$button-icon-size: toRem(16);
$border-radius: toRem(8);
$transition: .3s ease;
