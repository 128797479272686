$swiper-bullet-size: .75rem;

.swiper {
	padding-bottom: $gap-l;

	&-slide {

		&-inner {
			margin-inline: $gap-xl;

			@include break(mobile) {
				margin-inline: 0;
			}
		}
	}

	&-button {

		&-prev,
		&-next {

			&::after {
				font-size: $gap-s;
				color: $black;
			}

			@include break(mobile) {
				top: unset;
				bottom: 0;
			}
		}
	}

	&-pagination {
		top: unset;
		bottom: 0;

		&-bullet {
			width: $swiper-bullet-size;
			height: $swiper-bullet-size;

			&:nth-child(n):nth-child(n) {
				margin: 0 $gap-xxs;
			}

			&-active {
				background: $black;

				&:hover {
					cursor: default;
				}
			}
		}
	}
}