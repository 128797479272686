.grid {

	&-sidebar {
		@extend %container;
		display: grid;
		grid-template-columns: 1fr 4fr;
		grid-gap: $gap-xs;
		padding-block: $gap-xl;
		border-top: 1px solid $black;
		border-bottom: 1px solid $black;

		@include break(laptop) {
			grid-template-columns: 1fr 3fr;
		}

		@include break(tablet) {
			grid-template-columns: 1fr 2fr;
		}

		@include break(mobile) {
			grid-template-columns: 1fr;
			grid-gap: $gap-l;
		}

		&-nav {

			&.sticky {
				position: sticky;
				align-self: start;
				top: 1rem;

				@include break(mobile) {
					color: $white;
					padding: $gap-s;
					background-color: $black;
					z-index: $z-sticky-sidebar-nav-mobile;
				}

				a {
					@include break(mobile) {
						color: $white;
					}
				}
			}

			ul {
				padding: 0;
			}

			li {
				list-style-type: none;
			}

			.block {
				width: 100%;
				margin-inline: 0;
			}
		}
	}

	&-cards {
		@extend %container;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: $gap-xl $gap-m;

		@include break(laptop) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include break(tablet) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include break(mobile) {
			grid-template-columns: 1fr;
			grid-gap: $gap-l;
		}		
	}
}