$fifty-fifty-block-break: toRem(700);

.fifty-fifty {
	@include flex(space-between, flex-start, $gap-l);

	@include block-break($fifty-fifty-block-break) {
		flex-direction: column;
		align-items: stretch;
		gap: $gap-s;
	}

	&.stretch {
		@include flex(space-between, stretch, $gap-l);

		@include break(tablet) {
			align-items: flex-start;
		}
	}

	&>* {
		flex: 1;
	}

	&-image {

		img {
			@include object-fit;
		}
	}
}
