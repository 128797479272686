// font sizes
%font-xxxl {
	@include font(96, 112);

	@include break(tablet) {
		@include font(64, 80);
	}

	@include break(mobile) {
		@include font(56, 70);
	}
}

%font-xxl {
	@include font(72, 90);

	@include break(tablet) {
		@include font(56, 70);
	}

	@include break(mobile) {
		@include font(44, 55);
	}
}

%font-xl {
	@include font(64, 80);

	@include break(tablet) {
		@include font(44, 55);
	}

	@include break(mobile) {
		@include font(32, 55);
	}
}

%font-l {
	@include font(48, 60);

	@include break(tablet) {
		@include font(40, 50);
	}

	@include break(mobile) {
		@include font(24, 50);
	}
}

%font-m {
	@include font(32, 40);

	@include break(mobile) {
		@include font(20, 25);
	}
}

%font-s {
	@include font(24, 30);

	@include break(mobile) {
		@include font(20, 30);
	}
}

%font-xs {
	@include font(20, 30);

	@include break(mobile) {
		@include font(18, 28);
	}
}

// heads
%h1 {
	@include font-bold;
	@extend %font-xxxl;
}

%h2 {
	@include font-bold;
	@extend %font-xxl;
}

%h3 {
	@include font-bold;
	@extend %font-xl;
}

%h4 {
	@include font-bold;
	@extend %font-l;
}

%h5 {
	@include font-bold;
	@extend %font-m;
}

%head-link {
	color: inherit;
	text-decoration-color: transparent;
	text-decoration-thickness: .05em;
	transition: text-decoration-color .3s ease;
	
	&:hover {
		text-decoration-color: inherit;
	}
}

// paragraphs

%p {
	@include font-reg;
	@include font(16, 24);
}

%p-s {
	@include font-reg;
	@include font(14, 24);
}

%p-l {
	@include font-reg;
	@include font(18, 28);
}

%p-xl {
	@include font-reg;
	@include font(24, 36);

	@include break(tablet) {
		@include font(18, 28);
	}
}

// misc
%tag {
	@include font-bold;
	@include font(14, 20);
	text-transform: uppercase;

	@include break(tablet) {
		@include font(12, 16);
	}
}
