$themes: (
  ("white", $black, $white, "primary", "secondary", "black"),
  ("black", $white, $black, "secondary", "primary", "white")
);

// butttons
@each $color, $text, $bg, $primary-btn, $secondary-btn, $arrow in $themes {
	.theme-#{$color} {
		color: $text;
		background-color: $bg;

		a {
			color: $text;
		}

		.tag {
			color: $text;
		}

		.btn-primary {
			@extend %btn-#{$primary-btn};
		}

		.btn-secondary {
			@extend %btn-#{$secondary-btn};
		}

		.accordion-trigger {
			color: $text;

			&::after {
				background-image: url('/images/icons/accordion-arrow-' + $arrow + '.svg');
			}
		}
	}
}