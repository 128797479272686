// brand
$primary: $brand-brand1;
$secondary: $brand-brand2;

// general
$black: $colors-black;
$white: $colors-white;

$bg-medium: #EEEEEE;
$bg-light: #BBBBBB;
