.block {
	container: block / inline-size;

	&-inner {
		@extend %container;
	}

	&-padded {
		padding-block: $gap-l;

		@include break(mobile) {
			padding-inline: 0;
		}
	}
}

.grid-sidebar,
.swiper-slide-inner {

	.block {
	
		&-inner {
			width: 100%;
		}
	
		&-padded {
			padding: $gap-l;

			@include break(mobile) {
				padding-inline: $gap-s;
			}
		}
	}
}