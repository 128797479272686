/* global colors */
$colors-black: #000000;
$colors-blue: #005cff;
$colors-gray-200: #e5ebf1;
$colors-gray-300: #cbd5e1;
$colors-gray-400: #bcc2cb;
$colors-gray-500: #9199a5;
$colors-gray-600: #60656d;
$colors-gray-700: #414750;
$colors-gray-800: #242d39;
$colors-gray-900: #1d2331;
$colors-green: #25944e;
$colors-red: #cf2c2c;
$colors-white: #ffffff;

/* utility colors */
$utility-error: $colors-red;
$utility-focus: $colors-blue;
$utility-success: $colors-green;

/* element colors */
$background-light: $colors-gray-200;
$background-medium: $colors-gray-500;
$background-white: $colors-white;
$brand-brand1: $colors-gray-900;
$brand-brand2: $colors-gray-800;
$brand-brand3: $colors-gray-600;
$button-dark-active: $colors-gray-900;
$button-dark-disabled: $colors-gray-400;
$button-dark-hover: $colors-gray-700;
$button-dark-pressed: $colors-gray-500;
$button-dark-text-icon: $colors-white;
$button-light-active: $colors-white;
$button-light-hover: $colors-gray-400;
$button-light-pressed: $colors-gray-300;
$button-light-stroke: $colors-gray-900;
$button-light-text-icon: $colors-gray-900;
$button-medium-active: $colors-gray-600;
$button-medium-hover: $colors-gray-500;
$button-medium-pressed: $colors-gray-400;
$button-medium-text-icon: $colors-white;
$divider-dark: $colors-gray-700;
$divider-light: $colors-gray-300;
$field-active: $colors-gray-600;
$field-background: $colors-white;
$field-disabled: $colors-gray-400;
$field-error: $utility-error;
$field-pressed: $colors-gray-900;
$field-success: $utility-success;
$links-dark-active: $colors-gray-900;
$links-dark-hover: $colors-gray-700;
$links-dark-pressed: $colors-gray-500;
$links-light-active: $colors-white;
$links-light-hover: $colors-gray-400;
$links-light-pressed: $colors-gray-300;
$text-dark: $colors-gray-900;
$text-light: $colors-gray-200;
$text-white: $colors-white;

/* spacing */
$spacing-0: 0rem;
$spacing-xxs: 0.5rem;
$spacing-xs: 1rem;
$spacing-s: 1.5rem;
$spacing-m: 2rem;
$spacing-l: 3rem;
$spacing-xl: 4rem;
$spacing-xxl: 6rem;
$spacing-xxxl: 7.5rem;