:root {
	--header-nav-break: 1200;
}

// breaks
$screen-breaks: (
	"mobile": "620px",
	"tablet": "768px",
	"laptop": "1024px",
	"desktop": "1440px",
	"widescreen": "1920px",
	"header": "1200px"
);

@mixin break($width, $breaks: $screen-breaks) {

	@if map-has-key($breaks, $width) {
		$width: map-get($breaks, $width);
	}

	@media screen and (max-width: #{$width}) {
		@content;
	}
}

@mixin block-break($width) {
	@container (max-width: #{$width}) {
		@content;
	}
}