.accordion {

	&-group {

		h3 {
			@extend %p-l;
		}

	}

	&-trigger {
		@include button-reset;
		@include font-bold;
		@extend %p-xl;

		&::after {
			content: '';
			display: inline-block;
			width: $gap-xs;
			height: $gap-xs;
			margin-left: $gap-s;
			background-image: url('/images/icons/accordion-arrow-black.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			transform: scaleY(1);
			transition: transform $transition;
		}

		&:hover {
			&::after {
				transform: scale(1.3);
			}
		}

		&:focus-visible {
			outline: 1px solid $colors-gray-500;
			outline-offset: $sliver;
		}

		&[aria-expanded=true] {

			&::after {
				transform: scaleY(-1);
			}
		}
	}

	&-panel {
		visibility: hidden;
		overflow: hidden;
		transition: height $transition;

		&.open {
			visibility: visible;
		}

		&-content {
			max-width: $max-width;
			padding-top: $gap-xxs;
		}
	}
}