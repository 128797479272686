$dialog-width: 37.5rem;
$dialog-max-width: 90%;
$dialog-animation-duration: 400ms;
$dialog-animation-delay: 200ms;

.dialog {
	&-container,
	&-overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		@media (prefers-reduced-motion: reduce) {
			animation: none;
		}
	}

	&-container {
		display: flex;
		z-index: $z-modal;

		&[aria-hidden="true"] {
			display: none;
		}
	}

	&-overlay {
		background-color: rgb($black, 0.8);
		animation: fade-in 200ms both;
	}

	&-content {
		position: relative;
		width: $dialog-width;
		max-width: $dialog-max-width;
		margin: auto;
		padding: $gap-s;
		background-color: $white;
		border-radius: $border-radius;
		animation: fade-in $dialog-animation-duration $dialog-animation-delay both, 
				   slide-up $dialog-animation-duration $dialog-animation-delay both;
		z-index: $z-modal;
	}

	&-close {
		position: absolute;
		top: $gap-xxs;
		right: $gap-xxs;
		width: $gap-m;
		height: $gap-m;
		text-align: center;
		padding: $gap-xxs;
		background-color: transparent;
		border: 0;
		border-radius: 50%;
		transition: background-color $transition;
		cursor: pointer;

		&:hover {
			background-color: rgb($black, .15);
		}
	}

	&-title {
		@extend %h4;
	}
}