$header-logo-width: toRem(175);
$header-logo-width-desktop: toRem(175);
$header-logo-width-laptop: toRem(130);
$header-logo-width-mobile: toRem(130);

$header-subnav-width: toRem(245);
$header-subnav-width-at-break: 100%;

.header {
	position: relative;
	width: 100vw;
	background-color: $black;
	z-index: $z-header;

	&-container {
		@extend %container;
		@include flex(space-between, center, $gap-m);
		height: $header-height;

		@include break(header) {
			align-items: center;
			height: $header-height-laptop;
		}

		@include break(mobile) {
			height: $header-height-mobile;
		}
	}

	&-logo {
		display: flex;
		align-items: center;
		flex: 0 0 $header-logo-width;

		@include break(desktop) {
			flex: 0 0 $header-logo-width-desktop;
		}

		@include break(header) {
			flex: 0 0 $header-logo-width-laptop;
		}

		@include break(mobile) {
			flex: 0 0 $header-logo-width-mobile;
		}

		a {
			text-decoration: none;
			text-transform: uppercase;

			img,
			svg {
				display: block;
				width: 100%;
			}
		}
	}

	&-mobile-toggle {
		display: none;

		@include break(header) {
			display: block;
		}

		.nav-toggle {
			@include button-reset;
			font-size: toRem(18);
			line-height: 36px;
			position: relative;
			line-height: $header-height;
			text-transform: uppercase;
			color: $white;
			padding-right: $gap-l;

			@include break(header) {
				line-height: $header-height-laptop;
			}

			@include break(mobile) {
				font-size: toRem(16);
				line-height: $header-height-mobile;
				padding-right: $gap-m;
			}

			&[aria-pressed="true"] {
				.show-menu {
					display: none;
				}

				.hide-menu {
					display: inline-block;
				}
			}

			&[aria-pressed="false"] {
				.show-menu {
					display: inline-block;
				}

				.hide-menu {
					display: none;
				}
			}

			svg {
				position: absolute;
				top: 50%;
				width: $gap-s;
				height: auto;
				margin-left: $gap-xs;
				transform: translateY(-50%);
				pointer-events: none;

				&.show-menu {
					right: 0;
				}

				&.hide-menu {
					right: 10px;

					@include break(mobile) {
						width: $gap-xs;
					}
				}
			}
		}
	}

	&-nav {
		position: relative;

		@include break(header) {
			position: absolute;
			top: $header-height-laptop;
			left: 0;
			width: 100vw;
			height: 0;
			background-color: $black;
			overflow: hidden;
			transition: height 0.3s ease;
			z-index: $z-header;
		}

		@include break(mobile) {
			top: $header-height-mobile;
		}

		&[aria-hidden="false"] {
			@include break(header) {
				height: calc(100vh - #{$header-height-laptop});
				overflow: auto;
			}

			@include break(mobile) {
				height: calc(100vh - #{$header-height-mobile});
			}
		}

		&[aria-hidden="true"] {
			@include break(header) {
				height: 0;
				overflow: hidden;
			}
		}

		&-container {
			@include flex(flex-end, center, $gap-l);
			width: 100%;
			margin: 0;
			padding: 0;

			@include break(header) {
				display: block;
				text-align: center;
				padding: $gap-l;
			}
		}

		.nav-item {
			position: relative;
			@include flex(flex-start, center, 0);
			list-style-type: none;
			margin: 0;
			transition: height 0.3s ease;

			@include break(header) {
				display: block;
				text-align: left;
				padding-bottom: $gap-m;
			}

			.subnav-toggle {
				@include button-reset;
				padding: $gap-xs;

				&>* {
					pointer-events: none;
				}

				.arrow {
					display: block;
					transform: translateY(-$sliver) scaleY(-1);
					transform-origin: center;

					@include break(header) {
						transform: translateY(-1px) scaleY(-1);
					}
				}

				&:hover {
					cursor: pointer;
				}

				&[aria-pressed="false"] {
					.arrow {
						transform: translateY(-$sliver) scaleY(1);

						@include break(header) {
							transform: translateY(-1px) scaleY(1);
						}
					}
				}

				&[aria-pressed="true"] {
					background-color: $black;

					&::after {
						transform: scaleY(1) translateY(-8px);
					}
				}
			}

			.nav-link {
				position: relative;
				text-decoration: underline;
				text-decoration: none;
				color: $white;
				border-bottom: 8px solid transparent;
				transition: border-color 0.3s ease;

				&::before {
					position: absolute;
					bottom: -$sliver;
					left: 0;
					content: '';
					display: block;
					width: 100%;
					height: 2px;
					background-color: transparent;
					transition: background-color .3s ease;
				}

				&:hover {
					&::before {
						background-color: $white;
					}
				}

				&.current {
					@include font-bold;

					&:hover {
						cursor: default;
						
						&::before {
							display: none;
						}
					}
				}

				&.search {

					&::after {
						content: '';
						display: inline-block;
						width: 13px;
						height: 13px;
						margin-left: $gap-xxs;
						background-image: url('/images/icons/search.svg');
					}
				}
			}
		}

		.nav-subnav {
			position: absolute;
			top: 30px;
			left: -$gap-s;
			width: $header-subnav-width;
			margin: 0;
			padding: 0 $gap-s;
			background-color: $black;
			visibility: hidden;
			transition: height 0.3s ease;
			overflow: hidden;

			@include break(header) {
				position: relative;
				top: unset;
				width: $header-subnav-width-at-break;
				margin: 0 0 0 $gap-l;
				padding: 0;
				z-index: unset;
			}

			&[aria-hidden="false"] {
				visibility: visible;
			}

			.subnav-item {
				list-style-type: none;
				margin: 0;

				@include break(header) {
					text-align: left;
				}

				&:first-child {
					padding-top: $gap-xs;

					@include break(header) {
						padding-top: 0;
					}
				}

				&:last-child {

					.subnav-link {

						@include break(header) {
							padding-bottom: 0;
						}
					}
				}

				.subnav-link {
					display: block;
					color: $white;
					text-decoration: none;
					padding-block: 0 $gap-s;
					background-color: $black;

					@include break(header) {
						padding-block: $gap-xs;
					}

					&:hover {
						text-shadow:1px 0 0 $black;
					}
				}
			}
		}
	}
}