// container widths 
$container-width: toRem(1200);
$container-width-wide: toRem(1600);
$container-width-narrow: toRem(800);

$container-padding: $gap-xxl;
$container-padding-laptop: $gap-l;
$container-padding-tablet: $gap-m;
$container-padding-mobile: $gap-s;

// containers
%container,
.container {
	width: calc(100% - ($container-padding * 2));
	max-width: $container-width;
	margin-inline: auto;

	@include break(laptop) {
		width: calc(100% - ($container-padding-laptop * 2));
	}

	@include break(tablet) {
		width: calc(100% - ($container-padding-tablet * 2));
	}

	@include break(mobile) {
		width: calc(100% - ($container-padding-mobile * 2));
	}
}	

%container-wide,
.container-wide {
	@extend %container;
	max-width: $container-width-wide;
}

%container-narrow,
.container-narrow {
	@extend %container;
	max-width: $container-width-narrow;
}