@keyframes fade-in {
	from {
		opacity: 0;
	}
}

@keyframes slide-up {
	from {
		transform: translateY(10%);
	}
}