*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
	@include font-reg;
	@include font(16, 24);
	width: 100%;
	color: $black;
	scroll-behavior: smooth;
	overflow-x: hidden;
}

.main {
	//padding-top: $header-height; for fixed headers
 
	@include break(laptop) {
		//padding-top: $header-height-laptop;
	}

	@include break(mobile) {
		//padding-top: $header-height-mobile;
	}
}

h1,
.h1-style {
	@extend %h1;

	a {
		@extend %head-link;
	}
}

h2,
.h2-style {
	@extend %h2;

	a {
		@extend %head-link;
	}
}

h3,
.h3-style {
	@extend %h3;

	a {
		@extend %head-link;
	}
}

h4,
.h4-style {
	@extend %h4;

	a {
		@extend %head-link;
	}
}

h5,
.h5-style {
	@extend %h5;

	a {
		@extend %head-link;
	}
}

p, li {
	@extend %p;
}

.p-s {
	@extend %p-s;
}

.p-l {
	@extend %p-l;
}

.p-xl {
	@extend %p-xl;
}

.bold {
	@include font-bold;
}

.uppercase {
	text-transform: uppercase
}

.centered {
	text-align: center;
}

a {
	color: $black;
	text-decoration-thickness: 1px;
	transition: text-decoration-thickness $transition;

	&:hover {
		text-decoration-thickness: 2px;
	}

	&:focus-visible {
		outline: 1px solid inherit;
	}

	img {
		text-decoration: none;
	}
}

// misc 
.max-width {
	max-width: $max-width;
}

// Screen reader only text
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}