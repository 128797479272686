.card {
	@include flex(space-between, stretch, $gap-m);
	flex-direction: column;

	&.border {
		padding: $gap-s;
		border: 1px solid $black;
	}

	&-icon {
		display: block;
		width: $gap-xl;
		aspect-ratio: 1 / 1;
		
		img {
			@include object-fit;
		}
	}

	&-image {
		display: block;
		width: 100%;
		aspect-ratio: 1 / 1;
		overflow: hidden;

		img {
			@include object-fit;
		}
	}

	&-content {
		@include flex(space-between, flex-start, $gap-xs);
		flex-direction: column;
	}
}