// global mixins

// px to rems
$base-font-size: 16;

@function toRem($value) {
    $remValue: math.div($value, $base-font-size) + rem; 
    @return $remValue;
}

// font size/line height
@mixin font($size, $line-height) {
	font-size: toRem($size);
	line-height: calc($line-height / $size);
}

// custom container width 
@mixin container($width) {
	@extend %container;
	max-width: $width;
}

// object fit
@mixin object-fit($type: cover, $position: center) {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: $type;
	object-position: $position;
}

// flexbox
@mixin flex($justify: space-between, $align: flex-start, $gap: 0) {
	display: flex;
	justify-content: $justify;
	align-items: $align;
	gap: $gap;
}