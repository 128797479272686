// block spacing (css class, desktop spacing, mobile spacing)
$stacks: (
	"xxxl" toRem(120) toRem(96),
	"xxl" toRem(96) toRem(64),
	"xl" toRem(64) toRem(48),
	"l" toRem(48) toRem(40),
	"m" toRem(32) toRem(32),
	"s" toRem(24) toRem(24),
	"xs" toRem(16) toRem(16),
	"xxs" toRem(8) toRem(8)
);

// top/bottom spacing between elements	
@each $size, $value, $mobile-value in $stacks {
	.stack-#{$size} > * + * {
		margin-block-start: #{$value};

		@media screen and (max-width: 768px) {
			margin-block-start: #{$mobile-value};
		}
	}
}
