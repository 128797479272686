// colors
$btn-primary: $button-dark-active;
$btn-primary-text: $button-dark-text-icon;
$btn-primary-hover: $button-dark-hover;

$btn-secondary: $button-light-active;
$btn-secondary-text: $button-light-text-icon;
$btn-secondary-hover: $button-light-hover;

// misc
$btn-height: toRem(48);
$btn-inline-padding: toRem(16);
$btn-arrow-size: toRem(20);
$btn-arrow-translateY: toRem(6);

// button reset
@mixin button-reset {
	padding: 0;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	text-shadow: none;
	box-shadow: none;
	appearance: none;

	&:hover {
		cursor: pointer;
	}
}

%btn {
	@include button-reset;	
	display: inline-block;
	height: $btn-height;
	font-size: toRem(16);
	line-height: $btn-height;
	text-decoration: none;
	padding-inline: $btn-inline-padding;
	transition: $transition;
}

$btns: (
  ("primary", $btn-primary-text, $btn-primary, $btn-primary-hover),
  ("secondary", $btn-secondary-text, $btn-secondary, $btn-secondary-hover)
);

// butttons
@each $label, $color, $bg, $bg-hover in $btns {
	%btn-#{$label},
	.btn-#{$label} {
		@extend %btn;
		color: $color;
		background-color: $bg;

		&:hover {
			background-color: $bg-hover;
		}

		&:active {
			background-color: $bg-hover;
		}

		&:focus-visible {
			outline: 1px solid $bg;
			outline-offset: $sliver;
		}
	}
}

// arrow buttons
@each $label, $color, $bg, $bg-hover, $bg-active in $btns {
	.btn-#{$label}-arrow {
		@extend %btn;
		color: $color;
		background-color: $bg;

		&:hover {
			background-color: $bg-hover;

			&::after {
				transform: translate($sliver, $btn-arrow-translateY);
			}
		}

		&:active {
			background-color: $bg-hover;
		}

		&:focus-visible {
			outline: 1px solid $color;
			outline-offset: $sliver;
		}

		&::after {
			display: inline-block;
			content: '';
			width: $btn-arrow-size;
			height: $btn-arrow-size;
			margin-left: $gap-xxs;
			background-image: url(/images/icons/btn-arrow-#{$label}.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			transform: translate(0, $btn-arrow-translateY);
			transition: $transition;
		}
	}
}

// 100% width btns on mobile
.btn-mobile-wide:nth-child(n) {
	@include break(mobile) {
		width: 100%;
		padding-inline: 0;
	}
}