.cta-block {
	
	&-btns {
		@include flex(flex-start, center, $gap-l);

		@include break(mobile) {
			flex-direction: column;
			align-items: flex-start;
			gap: $gap-s;
		}
	}
}