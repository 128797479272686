.hero {
	position: relative;
	width: 100%;
	max-height: 75vh;
	aspect-ratio: 2 / 1;

	&-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: $z-hero-bg;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			width: 100%;
			height: 100%;
			background-color: rgba($black, .5);
		}

		img {
			@include object-fit;
		}
	}

	&-title {
		@extend %container;
		@include flex(flex-end, flex-end, 0);
		height: 100%;
		color: $white;
		padding-block: $gap-l;
	}
}